<template>
  <v-container>
    <v-row>
      <v-col cols="1" style="margin-left: 10px; padding-bottom: 23px">
        <BackArrow class="mt-2" @click="backPage()" />
      </v-col>
      <v-col cols="10" class="mt-1" style="margin-left: 10px">
        <p class="main-title">Telemedicina</p>
      </v-col>
    </v-row>

    <v-form
      id="formcadastro"
      ref="formcadastro"
      @submit.prevent="salvar"
      :lazy-validation="true"
    >
      <v-container
        class="d-flex flex-column align-space-between"
        fluid
        style="height: 80vh"
      >
        <v-col cols="12" class="text-left pb-7">
          <p>
            Ao confirmar o cancelamento,
            <b>os benefícios do serviço não estarão mais disponíveis</b> para o
            dependente selecionado e eventuais cobranças de mensalidade ainda
            poderão ser realizadas em folha de acordo com a data corte adotada
            pelo EMPREGADOR.
          </p>
          <p class="">
            Para confirmar o cancelamento do dependente abaixo, clique em
            "confirmar cancelamento".
          </p>
          <generic-card class="my-4" style="padding: 10px">
            <v-row class="my-0" align-content="center" align="center">
              <v-col cols="7" class="pa-0">
                <p class="text-h6 ma-0" style="color: var(--v-primary)">{{ dependent.personName }}</p>
              </v-col>
              <v-col cols="12" class="pa-0">
                <p class="status-text ma-0">
                  Dependente {{ title + 1 }}
                </p>
              </v-col>
            </v-row>
          </generic-card>
        </v-col>
        <generic-btn
          class="mb-4 mt-2"
          color="#E9EBFD"
          :defaultTheme="false"
          :btn-props="{ rounded: true, elevation: 8, block: true }"
          style="font-weight: bold"
          :on-click="() => confirmCancel(dependent.dependentId)"
        >
          CONFIRMAR CANCELAMENTO
        </generic-btn>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import genericCard from "../../../components/generic-card/index.vue";
import GenericBtn from "../../../components/generic-btn";
import BackArrow from "../../../components/covid/back-arrow/index.vue";
import ContractService from "../../../services/contract/ContractService";
import UserService from "@/services/security/User";

export default {
  components: {
    genericCard,
    GenericBtn,
    BackArrow,
  },
  data() {
    return {
      dependent: {},
      title: "",
    };
  },
  created() {
    this.userService = new UserService();
    this.contractService = new ContractService();
    this.dependent = this.$route.params.dependent;
    this.title = this.$route.params.title;
  },
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    backPage() {
      this.$router.push("/conectarComSuaEmpresa");
    },
    async confirmCancel(id) {
      this.loading(true);
      await this.contractService.CancelTelemedicineDependent(id)
        .then()
        .catch(() => {
          this.showmsg({ text: "Erro ao cancelar dependente.", type: "error" });
          this.loading(false);
        });

      await this.reloadSessionByToken();
      this.loading(false);

      this.$router.push({ name: "doneCancelTelemedicine" });
    },
    async reloadSessionByToken() {
      const token = this.$auth.getAccessToken();
      let reloadSessionResponse = await this.userService.reloadSessionByToken(token);
      try {
        if (reloadSessionResponse.data) {
          this.$auth.setUser(reloadSessionResponse.data.user);
        }
      } catch (e) {
        this.showmsg({text: "Erro ao recarregar dados da sessão.", type: "error"});
        this.loading(false);
      }
    },
  },
};
</script>
